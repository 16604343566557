import MDinput from '@/components/MDinput';
import { validatorRequire } from '@/utils/validators';
import DSESelect from '@/components/DSE/DSESelect';
import FileUpload from '@/components/DSE/FileUpload';

export function getFormDefinition(vue) {
  return {
    name: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    documentTypeID: {
      type: DSESelect,
      props: {
        caption: 'common.documentType',
        selectFirstOnDefault: true,
        required: true,
        valueField: 'id',
        labelField: 'name',
        loading: vue.$store.getters['documentType/isLoading'],
        options: vue.$store.getters['documentType/loadedItems']
      }
    },
    file: {
      type: FileUpload,
      isImageYN: false,
      showDownloadButton: true,
      hideOnCreate: true,
      caption: 'document.contents',
      props: {
        openFileInNewWindow: true,
        downloadAction: 'template/downloadTemplate',
        uploadRoute: 'template/content',
        downloadFromEntity: true
      }
    }
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  // Filename is already being set by the file upload function
  delete form.filename;
  return form;
}

export function createFormDataFromItem(vue, item) {
  const form = {
    ...item
  };

  return form;
}
