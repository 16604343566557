import { getFormDefinition, createFormDataFromItem, createItemFromFormData } from './form';

import { entityToStore } from '@/utils/store';

import baseCrudMixin from '@/mixins/crud';

export default {
  computed: {
    definition() {
      return this.createDefinition();
    },

    entityStore() {
      return entityToStore(this.entity);
    }
  },

  mixins: [baseCrudMixin],

  data: () => ({
    entity: 'Template',
    returnTo: 'Templates'
  }),

  methods: {
    getDependencies(data) {
      return [{ entity: 'documentType' }];
    },

    createDefinition() {
      return getFormDefinition(this);
    },

    createFormDataFromItem,

    createItemFromFormData
  }
};
